<template>
  <div id="ExchangeShare">
    <TheHeader></TheHeader>
    <div class="container">
      <h1 class="brand-color-dark text-center">Diensttausch</h1>
      <p class="text-center">
        Du siehst einen Diensttausch, der über den StadtbahnfahrerClub angeboten
        wird.
      </p>

      <p class="text-center">
        Als angemeldeter StadtbahnfahrerClub Nutzer kannst Du Deinen Dienst
        sekundenschnell gegen diesen Dienst tauschen.
      </p>

      <div class="content-card-container card-one-center text-center">
        <div class="content-card">
          <div class="card-header bg-brand card-header-flex">
            <p>Dienstag, den 04.05.21</p>
            <i class="icon color-shift-middle fas fa-cloud"></i>
          </div>
          <div class="card-content">
            <div>
              <p><b>Umlaufstück</b></p>
              <p><b>4/62</b></p>
              <p>13:34 Uhr - NEU BOC</p>
              <p>15:28 Uhr - FSP BOC</p>
            </div>

            <div>
              <p><b>Umlaufstück</b></p>
              <p><b>4/62</b></p>
              <p>13:34 Uhr - NEU BOC</p>
              <p>15:28 Uhr - FSP BOC</p>
            </div>

            <hr />

            <div class="card-content-badges">
              <i class="icon color-shift-middle fas fa-cloud fa-fw"></i>
              <div class="badge badge-white">
                <i class="fas fa-map-marker"></i> MRH-S
              </div>
              <div class="badge badge-white">
                <i class="fas fa-caret-right"></i> NEU
              </div>
              <div class="badge badge-white">
                <i class="fas fa-caret-right"></i> NEU
              </div>
              <div class="badge badge-white">
                <i class="fas fa-caret-right"></i> NEU
              </div>
              <div class="badge badge-white">
                <i class="fas fa-caret-right"></i> NEU
              </div>
              <div class="badge badge-white">
                <i class="fas fa-caret-right"></i> NEU
              </div>
              <div class="badge badge-white">
                <i class="fas fa-caret-right"></i> NEU
              </div>
              <div class="badge badge-white">EBO</div>
            </div>
            <p class="commentar">
              "Suche einen Mitteldienst mit Einfahrt Btf. Merheim mit
              vergleichbarer Endzeit."
            </p>
          </div>
          <div class="card-footer">
            <a class="card-button">A</a> <a class="card-button">A</a>
          </div>
        </div>
      </div>

      <a class="button-outline text-center">Anmelden & Dienst tauschen</a>

      <hr class="space" />

      <h4 class="brand-color-dark text-center">Noch nicht dabei?</h4>
      <p class="text-center">
        Der StadtbahnfahrerClub ist exklusiv für Stadtbahnfahrer. Trete
        kostenfrei bei, um Vorteile für Deinen Arbeitsalltag zu erhalten.
      </p>

      <a class="button-outline text-center">Kostenfrei registrieren</a>
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";

export default {
  name: "ExchangeShare",
  components: {
    SiteNav,
    TheHeader
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>